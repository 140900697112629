body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: sans-serif;
}

a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
  border-bottom: 1px solid #eee;
}
a:hover {
  color: black;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 900;
  margin: 0;
}
